import {
  UserRole,
  UserRoleList,
  type AuthSignupDto,
  type AuthSigninDto,
  type StructureModule,
  AuthRecoveryDto,
AuthRecoveryVerifyDto,
} from '@/interfaces';

export const useStructureAuth = () => {
  const { t } = useI18n();

  const getSigninStructure = (): StructureModule<AuthSigninDto> => ({
    inputs: [
      {
        grid: '1 / 13',
        id: 'email',
        type: 'text',
        name: '',
        placeholder: 'company@example.com',
        show: true,
        required: true,
      },
      {
        grid: '1 / 13',
        id: 'password',
        type: 'password',
        name: '',
        placeholder: t('dictionaries.structure.auth.password'),
        show: true,
        required: true,
      },
    ],
    submit: {
      text: t('dictionaries.structure.auth.toComeIn'),
      classMods: 'btn-black btn-center',
    },
    data: {
      email: '',
      password: '',
    },
  });

  const getSignupStructure = (): StructureModule<AuthSignupDto> => ({
    inputs: [
      {
        grid: '1 / 13',
        id: 'email',
        type: 'text',
        name: 'E-mail',
        placeholder: 'company@example.com',
        show: true,
        required: true,
      },
      {
        grid: '1 / 13',
        id: 'password',
        type: 'password',
        name: t('dictionaries.structure.auth.password'),
        placeholder: t('dictionaries.structure.auth.password'),
        show: true,
        required: true,
      },
      // {
      //   grid: '1 / 13',
      //   id: 'role',
      //   type: 'selector-single',
      //   name: 'Роль',
      //   placeholder: '',
      //   drop: UserRoleList,
      //   show: true,
      //   required: true,
      // },
    ],
    submit: {
      text: t('dictionaries.structure.auth.register'),
      classMods: 'btn-black btn-center',
    },
    data: {
      email: '',
      password: '',
      // role: '' as UserRole,
    },
  });

  const getRecoveryStructure = (): StructureModule<AuthRecoveryDto> => ({
    inputs: [
      {
        grid: '1 / 13',
        id: 'email',
        type: 'text',
        name: '',
        placeholder: 'company@example.com',
        show: true,
        required: true,
      },
    ],
    submit: {
      text: t('dictionaries.structure.auth.toSendALetter'),
      classMods: 'btn-black btn-center',
    },
    data: {
      email: '',
    },
  });

  const getRecoveryVerifyStructure = (): StructureModule<AuthRecoveryVerifyDto> => ({
    inputs: [
      {
        grid: '1 / 13',
        id: 'password',
        type: 'password',
        name: '',
        placeholder: t('dictionaries.structure.auth.newPassword'),
        show: true,
        required: true,
      },
      {
        grid: '1 / 13',
        id: 'repeatPassword',
        type: 'password',
        name: '',
        placeholder: t('dictionaries.structure.auth.repeat'),
        show: true,
        required: true,
      },
    ],
    submit: {
      text: t('dictionaries.structure.auth.updatePassword'),
      classMods: 'btn-black btn-center',
    },
    data: {
      password: '',
      repeatPassword: '',
    },
  });

  return {
    signinStructure: getSigninStructure(),
    signupStructure: getSignupStructure(),
    recoveryStructure: getRecoveryStructure(),
    recoveryVerifyStructure: getRecoveryVerifyStructure(),
  }
}
